import React from 'react';

export const appStore = (
  <svg width="100%" height="100%" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.429 11.6709C15.4189 9.94213 16.2021 8.6392 17.7839 7.67848C16.8992 6.41103 15.5608 5.71394 13.7965 5.57959C12.126 5.44777 10.2984 6.55299 9.62917 6.55299C8.92194 6.55299 7.30468 5.62522 6.03217 5.62522C3.40602 5.66578 0.615112 7.71903 0.615112 11.8965C0.615112 13.131 0.840717 14.4061 1.29193 15.7191C1.89523 17.4479 4.07016 21.6837 6.33889 21.6153C7.52521 21.5874 8.36426 20.7737 9.90801 20.7737C11.4061 20.7737 12.1818 21.6153 13.505 21.6153C15.794 21.5823 17.7611 17.7318 18.334 15.998C15.2642 14.5506 15.429 11.7596 15.429 11.6709ZM12.7648 3.93952C14.05 2.41352 13.9334 1.0244 13.8954 0.525024C12.7598 0.590931 11.4467 1.29817 10.6989 2.16763C9.87505 3.10047 9.39089 4.25384 9.49482 5.55424C10.7217 5.64803 11.8421 5.01684 12.7648 3.93952Z" fill="white"/>
  </svg>
);

export const googlePlay = (
  <svg width="100%" height="100%" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2728 9.88684L4.21887 0.80833L15.7383 7.42133L13.2728 9.88684ZM1.85591 0.275024C1.3226 0.553984 0.965698 1.06268 0.965698 1.72316V19.8268C0.965698 20.4873 1.3226 20.996 1.85591 21.275L12.3826 10.7729L1.85591 0.275024ZM19.2991 9.52994L16.8828 8.13103L14.1876 10.7771L16.8828 13.4231L19.3483 12.0242C20.0868 11.4375 20.0868 10.1166 19.2991 9.52994ZM4.21887 20.7458L15.7383 14.1328L13.2728 11.6673L4.21887 20.7458Z" fill="white"/>
  </svg>
);

export const closeIcon = (
  <svg width="100%" height="100%" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.376953" y="25.729" width="35" height="5" rx="2.5" transform="rotate(-45 0.376953 25.729)" fill="white"/>
    <rect x="3.91211" y="0.980225" width="35" height="5" rx="2.5" transform="rotate(45 3.91211 0.980225)" fill="white"/>
  </svg>
);

export const arrowDown = (
  <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.75L7.5 8.25L15 0.75H0Z" fill="#C63495" />
  </svg>
);

export const thinArrowDown = (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.901184 0.987183L3.99999 4.08598L7.09879 0.987183" stroke="white" strokeLinecap="round"/>
  </svg>
);

export const playButton = (
  <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6226 0.380249C8.80229 0.380249 0.0288086 9.15373 0.0288086 19.9741C0.0288086 30.7944 8.80229 39.5679 19.6226 39.5679C30.443 39.5679 39.2164 30.7944 39.2164 19.9741C39.2164 9.15373 30.443 0.380249 19.6226 0.380249ZM25.925 20.2758L16.373 27.2255C16.3207 27.2631 16.2589 27.2856 16.1946 27.2904C16.1303 27.2953 16.0659 27.2823 16.0085 27.2529C15.9511 27.2235 15.9029 27.1789 15.8692 27.1239C15.8356 27.0689 15.8177 27.0057 15.8176 26.9412V13.0506C15.8174 12.986 15.835 12.9226 15.8687 12.8674C15.9023 12.8122 15.9505 12.7674 16.008 12.738C16.0656 12.7086 16.1301 12.6956 16.1945 12.7006C16.259 12.7056 16.3207 12.7284 16.373 12.7663L25.925 19.7116C25.9701 19.7436 26.0069 19.7858 26.0323 19.8349C26.0578 19.884 26.071 19.9385 26.071 19.9937C26.071 20.049 26.0578 20.1035 26.0323 20.1526C26.0069 20.2016 25.9701 20.2439 25.925 20.2758Z" fill="white"/>
  </svg>
);
